<template>
    <div class="root-login">
        <mask-canvas />
        <div class="login-con">
            <p class="title">a-puhaha</p>
            <a-form id="components-form-demo-normal-login" :form="form" class="login-form" @submit="handleSubmit">
                <a-form-item>
                    <fc-typing-input ref="adminInput" v-model="rootName" placeholder="admin"> </fc-typing-input>
                </a-form-item>
                <a-form-item class="login-form-item-mb">
                    <fc-typing-input ref="passInput" v-model="rootPass" type="password" :value="rootPass" placeholder="password"></fc-typing-input>
                </a-form-item>
                <a-form-item class="login-form-item-mb">
                    <a-checkbox
                        v-decorator="[
                            'remember',
                            {
                                valuePropName: 'checked',
                                initialValue: true,
                            },
                        ]"
                    >
                        Remember me
                    </a-checkbox>
                </a-form-item>
                <a-form-item style="margin-bottom: 80px">
                    <a-button type="primary" html-type="submit" class="login-form-button" block> Log in </a-button>
                </a-form-item>
            </a-form>
        </div>
    </div>
</template>

<script>
    import {FcTypingInput} from 'fancy-components'
    import MaskCanvas from '@/components/MaskCanvas.vue';
    import {mapActions} from 'vuex';
    new FcTypingInput()
    export default {
        name: 'RootLogin',
        components: {
            MaskCanvas
            // FcArrowBtn
        },
        data() {
            return {
                rootName: '',
                rootPass: ''
            };
        },
        methods: {
            ...mapActions({vxRootLogin: 'root/rootLogin', vxRootInit: 'root/rootInit'}),
            handleSubmit(e) {
                e.preventDefault();
                this.form.validateFields(async (err, values) => {
                    if (!err) {
                        // console.log('Received values of form: ', values);
                        console.log(this.$refs.passInput.value);
                        let form = {...values, rootName: this.$refs.adminInput.value, rootPass: this.$refs.passInput.value}
                        console.log(form);
                        try {
                            const data = await this.vxRootLogin(form)
                            console.log(data);
                            if (data.code) {
                                this.$message.success(data.value)
                                this.$router.push({name: 'rootHome'})
                            } else {
                                this.$message.error(data.value)

                            }

                        } catch (error) {
                            this.$message.error(data.value)
                        }
                    }
                });
            },
            async rootInit() {
                console.log('aaa');
                await this.vxRootInit()
            }

        },
        beforeCreate() {
            this.form = this.$form.createForm(this, {name: 'normal_login'});
        },
        mounted() {
            this.rootInit()

        },
    };
</script>

<style lang="less" scoped>
    .root-login {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 100vh;
        background-color: #f0f2f5;
        z-index: 1;
        .login-con {
            .title {
                margin-bottom: 15px;
                font-size: 67px;
                text-align: center;
                font-family: 'Shelf Life Regular';
            }

            /deep/ fc-typing-input {
                --box-shadow-focus: 0 0 2px #1890ff;
                --color: #1890ff;
                --placeholder-animate-color: #1890ff;
                --height: 32px;
                --width: 368px;
                background: #ffffff;
                #r.filled > #c::after {
                    transform: scale(70);
                }
            }
            .login-form-item-mb {
                margin-bottom: 10px;
            }
        }
    }
</style>